import * as React from "react";
function SvgIcInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.416 5.804c.75.034 1.121.156 1.347.244.302.117.498.245.722.469.224.224.352.42.469.722.088.226.21.597.244 1.347.04.883.049 1.139.049 3.415 0 2.277-.01 2.534-.05 3.417-.034.749-.155 1.12-.243 1.346l.702.273-.702-.273a1.896 1.896 0 01-.47.722c-.223.224-.419.352-.721.47-.226.087-.598.209-1.347.243-.883.04-1.14.05-3.416.05-2.277 0-2.533-.01-3.416-.05-.75-.034-1.121-.156-1.347-.244a1.897 1.897 0 01-.722-.469 1.898 1.898 0 01-.469-.722c-.088-.226-.21-.597-.244-1.346-.04-.883-.049-1.14-.049-3.416 0-2.277.01-2.534.05-3.416.034-.75.155-1.121.243-1.347.118-.303.245-.498.47-.722.223-.224.419-.352.721-.47l-.273-.702.273.703c.226-.088.598-.21 1.347-.244.883-.04 1.14-.05 3.416-.05 2.277 0 2.533.01 3.416.05zm-8.452-.46c-.398.155-.683.34-.982.638a2.646 2.646 0 00-.638.982c-.117.301-.256.753-.294 1.586C5.009 9.45 5 9.72 5 12c0 2.28.009 2.55.05 3.45.038.833.177 1.285.294 1.586.155.398.34.683.638.981.3.3.584.484.982.64.301.116.753.255 1.586.293.9.041 1.17.05 3.45.05 2.28 0 2.55-.009 3.45-.05.833-.038 1.285-.177 1.586-.294.398-.155.683-.34.981-.639.3-.298.484-.583.64-.981.116-.301.255-.753.293-1.586.041-.9.05-1.17.05-3.45 0-2.28-.009-2.55-.05-3.45-.038-.833-.177-1.285-.294-1.586a2.646 2.646 0 00-.639-.982 2.647 2.647 0 00-.981-.638c-.301-.117-.753-.256-1.586-.294C14.55 5.009 14.28 5 12 5c-2.28 0-2.55.009-3.45.05-.833.038-1.285.177-1.586.294zm9.404 2.458a.271.271 0 11-.542 0 .271.271 0 01.542 0zm-.275 1.029a1.025 1.025 0 100-2.05 1.025 1.025 0 000 2.05zm-3.812 6.721a3.302 3.302 0 100-6.604 3.302 3.302 0 000 6.604zm4.056-3.302a4.056 4.056 0 11-8.112 0 4.056 4.056 0 018.112 0z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcInstagram;
