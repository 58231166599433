import * as React from "react";
function SvgIcYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.556 17.65c1.294.35 6.235.35 6.448.35.212 0 5.153-.01 6.447-.35.404-.1.778-.321 1.071-.612.293-.29.506-.66.627-1.091.404-2.264.535-5.71-.02-7.924a2.311 2.311 0 00-.617-1.061c-.293-.29-.667-.501-1.071-.611C17.147 6 12.206 6 11.993 6c-.212 0-5.154.01-6.447.36-.404.1-.778.321-1.071.612-.293.29-.506.66-.627 1.091-.384 2.254-.546 5.69.02 7.914.111.4.324.77.617 1.061.293.29.667.501 1.071.611zm.173-10.63c1.202-.33 6.215-.34 6.265-.34.05 0 5.053 0 6.266.33.293.07.546.22.758.431.212.21.364.46.434.741.526 2.104.405 5.43.01 7.593-.07.29-.222.54-.434.751-.212.2-.475.35-.758.43-1.203.331-6.215.331-6.266.331-.05 0-5.063 0-6.265-.33a1.645 1.645 0 01-.758-.43c-.202-.201-.354-.462-.435-.742-.515-2.024-.394-5.37-.01-7.583.07-.28.223-.54.435-.751.212-.2.475-.35.758-.43zm4.646 7.75a.342.342 0 01-.172-.05.35.35 0 01-.181-.3V9.572c0-.12.07-.24.181-.3a.37.37 0 01.354 0l4.224 2.424a.34.34 0 01.172.3c0 .12-.07.24-.172.3l-4.224 2.425a.414.414 0 01-.182.05zm.357-.97v-3.636l3.163 1.823-3.163 1.813z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcYoutube;
