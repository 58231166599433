import * as React from "react";
function SvgIcSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.26 9.305a3.66 3.66 0 114.068 6.087 3.66 3.66 0 01-4.069-6.087zm.416 5.463a2.911 2.911 0 103.235-4.84 2.911 2.911 0 00-3.235 4.84z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.375 7.946c.53.607.944 1.304 1.224 2.059h1.921v4.55h-1.872a6.788 6.788 0 01-1.21 2.118l.944 1.637-3.94 2.274-.936-1.621c-.804.15-1.629.15-2.433-.001l-.951 1.647-3.94-2.275.961-1.665a6.652 6.652 0 01-1.192-2.064H4v-4.55h1.964a6.64 6.64 0 011.177-2.028l-1.01-1.721 3.95-2.282.981 1.711a6.613 6.613 0 012.352-.022L14.396 4l3.943 2.276-.964 1.67zm.721 5.858h1.675v-3.051H18.06l-.084-.259A5.942 5.942 0 0016.64 8.25l-.192-.203.866-1.498-2.643-1.526-.877 1.529-.266-.056a5.902 5.902 0 00-2.573.025l-.27.062-.88-1.535L7.16 6.575l.904 1.542-.187.204a5.93 5.93 0 00-1.288 2.222l-.083.264-1.757-.003v3.05h1.748l.082.263a5.927 5.927 0 001.299 2.252l.185.202-.858 1.486 2.642 1.526.85-1.472.268.06c.87.203 1.776.204 2.647.002l.269-.06.835 1.445 2.641-1.525-.841-1.458.185-.203a5.917 5.917 0 001.314-2.302l.08-.266z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcSettings;
