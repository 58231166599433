import * as React from "react";
function SvgIcPieChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.642 12.18l-.265-.266-5.08 5.084.32.262a7.537 7.537 0 009.52 0l.32-.262-.291-.293c-.358-.36-4.524-4.525-4.524-4.525zM7.42 16.933l3.955-3.958c.786.786 3.1 3.098 3.957 3.958a6.79 6.79 0 01-7.912 0zM17.246 6.625l-.262-.32-5.093 5.093 5.082 5.082.263-.319a7.533 7.533 0 00.01-9.536zm-.336 8.732l-3.959-3.96L16.92 7.43a6.786 6.786 0 01-.009 7.927zM10.824 11.389l-.001-7.522-.414.044A7.166 7.166 0 006.022 6.08a7.484 7.484 0 00-1.053 9.325v.001c.16.253.334.497.523.729l.262.321.293-.292c.392-.39 4.667-4.664 4.667-4.664l.11-.11zm-5.003 3.94a6.738 6.738 0 01.73-8.72 6.503 6.503 0 013.522-1.895v6.364c-.58.581-3.298 3.299-4.252 4.251zM16.109 5.519a7.494 7.494 0 00-4.117-1.65l-.406-.033v6.79l4.846-4.846-.323-.261zm-3.773 3.297V4.661c1.07.153 2.088.56 2.968 1.187l-2.968 2.968z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcPieChart;
