import * as React from "react";
function SvgIcScatterplotChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.866 15.224c-1.538 0-2.788-1.242-2.788-2.769a2.781 2.781 0 012.788-2.769 2.781 2.781 0 012.787 2.77c0 1.526-1.25 2.768-2.787 2.768zm0-4.583a1.822 1.822 0 00-1.826 1.814c0 1 .819 1.814 1.826 1.814a1.822 1.822 0 001.826-1.814c0-1-.82-1.814-1.826-1.814zm-5.04 5.538A1.822 1.822 0 012 14.366c0-1 .82-1.814 1.826-1.814 1.007 0 1.826.814 1.826 1.814s-.82 1.813-1.826 1.813zm0-2.672a.863.863 0 00-.864.859c0 .473.387.858.864.858a.863.863 0 00.865-.858.863.863 0 00-.865-.86zm10.578 2.672a1.822 1.822 0 01-1.826-1.813c0-1 .82-1.814 1.826-1.814 1.007 0 1.826.814 1.826 1.814s-.82 1.813-1.826 1.813zm0-2.672a.863.863 0 00-.864.859c0 .473.388.858.864.858a.863.863 0 00.864-.858.863.863 0 00-.864-.86zm5.29 2.672a2.302 2.302 0 01-2.308-2.291 2.302 2.302 0 012.307-2.291A2.302 2.302 0 0122 13.887a2.302 2.302 0 01-2.307 2.292zm0-3.627c-.743 0-1.346.599-1.346 1.336 0 .737.603 1.336 1.345 1.336s1.345-.6 1.345-1.336c0-.737-.603-1.336-1.345-1.336zM9.114 8.538c-.742 0-1.345-.6-1.345-1.336 0-.737.603-1.336 1.345-1.336s1.345.599 1.345 1.336c0 .737-.603 1.336-1.345 1.336zm0-1.717a.383.383 0 00-.384.38.383.383 0 00.768 0 .383.383 0 00-.384-.38zm10.578-1.149c-.742 0-1.345-.599-1.345-1.336C18.348 3.6 18.95 3 19.693 3s1.345.6 1.345 1.336c0 .737-.603 1.336-1.345 1.336zm0-1.717a.383.383 0 00-.384.381.383.383 0 00.768 0 .383.383 0 00-.384-.38zM6.23 20c-.742 0-1.345-.6-1.345-1.336 0-.737.603-1.336 1.345-1.336s1.345.599 1.345 1.336C7.575 19.4 6.972 20 6.23 20zm0-1.717a.383.383 0 00-.383.38.383.383 0 00.767 0 .383.383 0 00-.384-.38zm9.038-6.687c-2.12 0-3.846-1.713-3.846-3.82 0-2.107 1.726-3.82 3.846-3.82 2.122 0 3.847 1.713 3.847 3.82 0 2.107-1.725 3.82-3.846 3.82zm0-6.686c-1.59 0-2.884 1.286-2.884 2.866s1.294 2.865 2.884 2.865c1.591 0 2.886-1.285 2.886-2.865 0-1.58-1.295-2.866-2.886-2.866z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcScatterplotChart;
