import * as React from "react";
function SvgIcEyedropper(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.183 5.58a2.495 2.495 0 00-3.524 0l-2.086 2.086-.838-.838-2.082 2.082 1.206 1.207-5.297 5.297-1.335 3.122 1.048 1.048 2.95-1.298 5.422-5.382 1.163 1.164 2.083-2.082-.796-.796 2.086-2.086a2.495 2.495 0 000-3.524zM8.827 17.57l-2.843 1.251 1.212-2.887 5.24-5.24 1.634 1.634-5.243 5.242zm6.983-4.655L11.806 8.91l.929-.929 4.005 4.005-.93.929zm2.797-4.387l-2.086 2.086-2.371-2.371 2.086-2.086a1.677 1.677 0 012.37 2.37z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcEyedropper;
