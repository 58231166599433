import * as React from "react";
function SvgIcLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.293 16.055l-3.342-3.344a2.278 2.278 0 00-2.898-.188l-.822-.822a2.276 2.276 0 00-.177-2.885L8.712 5.473A2.292 2.292 0 105.625 8.86l3.207 3.209a2.277 2.277 0 002.87.162l.82.822a2.28 2.28 0 00.341 3.046l3.208 3.209a2.291 2.291 0 003.222-3.253zm-8.921-4.142a1.531 1.531 0 01-1.023-.386L6.147 8.322l-.019-.018A1.542 1.542 0 118.19 6.011l3.321 3.322a1.523 1.523 0 01.179 1.826l-.402-.402-.53.53.402.402c-.237.146-.51.223-.788.224zm7.239 7.24a1.53 1.53 0 01-1.023-.387l-3.203-3.205-.018-.018a1.545 1.545 0 01-.527-1.16c.002-.28.08-.552.224-.789l.402.402.53-.529-.402-.403a1.525 1.525 0 011.835.185l3.322 3.324a1.542 1.542 0 01-1.14 2.58z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcLink;
