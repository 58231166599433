import * as React from "react";
function SvgIcRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02 9.293C7.11 6.43 9.73 4 13.511 4c4.16 0 7.512 3.37 7.512 7.5h.002c0 .734-.082 1.446-.237 2.125l.949.228C21.909 13.1 22 12.31 22 11.5h-.002c0-4.706-3.812-8.5-8.487-8.5-4.24 0-7.179 2.739-8.394 5.917l-.123-.076L3.711 6.5 3 6.91l1.756 3.204 3.584.042.01-.837-2.33-.026zm14.925 6.79c-.718 1.452-1.77 2.676-3.045 3.54l-.537-.834c1.128-.765 2.068-1.855 2.713-3.16l.87.453zM8.948 19.64a9.757 9.757 0 01-3.27-3.471l.845-.497A8.767 8.767 0 009.46 18.79l-.512.85zm4.53 1.36a8.088 8.088 0 01-2.362-.358l.278-.958a7.139 7.139 0 002.085.316 6.76 6.76 0 002.027-.31l.285.956a7.714 7.714 0 01-2.312.354z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcRefresh;
