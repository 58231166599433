import * as React from "react";
function SvgIcFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.907 19.5H9.912a.371.371 0 01-.372-.362v-6.895H8.372A.371.371 0 018 11.88V9.493c0-.196.17-.362.372-.362H9.54V8.015c0-2.337 1.275-3.515 3.802-3.515.903 0 1.583.134 2.358.29a.378.378 0 01.298.413l-.372 2.285a.338.338 0 01-.16.237.395.395 0 01-.286.062 8.264 8.264 0 00-1.253-.134c-.648 0-.648.114-.648.693v.785h2.018a.39.39 0 01.276.114.337.337 0 01.095.28l-.212 2.387a.375.375 0 01-.372.33H13.28v6.896c0 .197-.17.362-.372.362zm-2.623-.724h2.251v-6.895c0-.196.17-.362.372-.362h1.837l.15-1.664h-1.987a.371.371 0 01-.372-.362V8.335c0-.682.106-1.416 1.392-1.416.265 0 .658.031 1.02.083L15.2 5.42c-.616-.124-1.158-.207-1.859-.207-2.113.01-3.059.869-3.059 2.802v1.478c0 .197-.17.362-.371.362H8.743v1.664h1.169c.202 0 .371.166.371.362v6.895z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcFacebook;
