import * as React from "react";
function SvgIcListNumber(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.416 7.997h-.604V6.345v-.168a70.336 70.336 0 00.016-.4 3.642 3.642 0 01-.208.196l-.328.264L4 5.873l.92-.732h.496v2.856zM6.004 13.896H4.008v-.42l.716-.724c.144-.15.26-.273.348-.372.088-.101.152-.192.192-.272.04-.083.06-.17.06-.264 0-.115-.032-.2-.096-.256a.36.36 0 00-.252-.084.723.723 0 00-.32.076 1.73 1.73 0 00-.328.216L4 11.408c.083-.072.17-.139.26-.2.093-.061.2-.11.32-.148.123-.04.27-.06.44-.06.187 0 .347.035.48.104a.735.735 0 01.424.668c0 .12-.019.23-.056.332-.035.101-.087.2-.156.296-.067.093-.15.19-.248.292-.099.099-.21.207-.336.324l-.368.344v.028h1.244v.508zM5.892 16.68a.663.663 0 01-.304.576.973.973 0 01-.312.136v.012c.23.027.403.096.52.208.12.112.18.263.18.452a.833.833 0 01-.124.448.82.82 0 01-.376.312c-.168.075-.385.112-.652.112-.157 0-.304-.013-.44-.04A1.948 1.948 0 014 18.78v-.512a1.792 1.792 0 00.76.188c.216 0 .367-.037.452-.112a.392.392 0 00.132-.316c0-.08-.02-.147-.06-.2-.04-.056-.11-.097-.212-.124a1.531 1.531 0 00-.416-.044H4.44v-.464h.22c.176 0 .31-.016.4-.048.093-.035.156-.08.188-.136a.386.386 0 00.052-.2.297.297 0 00-.096-.236c-.061-.059-.165-.088-.312-.088a.807.807 0 00-.248.036 1.081 1.081 0 00-.204.08 2.45 2.45 0 00-.156.092l-.28-.416a1.487 1.487 0 01.556-.244A1.8 1.8 0 014.936 16c.293 0 .525.06.696.18.173.117.26.284.26.5zM8 6h12v1.09H8V6zM8 11.453h12v1.091H8v-1.09zM8 16.906h12v1.091H8v-1.09z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcListNumber;
