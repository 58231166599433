import * as React from "react";
function SvgIcDuplicate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.324 6.723V4H8.44v.058L5.05 8.723H5v9.138h2.191v2.723h11.324V6.723h-2.191zm-.75-1.973v1.973h-4.942v.058L9.19 8.765V4.75h6.384zm-4.942 3.306v3.39H8.169l2.463-3.39zM8.44 5.334v3.389H5.977L8.44 5.334zM5.75 17.111V9.473h2.926l-1.434 1.972h-.05v5.666H5.75zm12.015 2.723H7.941v-7.639h3.44V7.473h6.384v12.361z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcDuplicate;
