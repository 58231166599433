import * as React from "react";
function SvgIcLayoutOptions(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.968 9.864l-.282-.109-.182.238a3.755 3.755 0 01-2.331 1.033l5.41-5.325L16.84 4l-6.61 6.506c-.17.071-.334.155-.49.253a2.723 2.723 0 01-1.244-.766l-.182-.238-.282.11C5.545 10.822 4 12.628 4 14.576 4 17.567 7.589 20 12 20a10.784 10.784 0 004.593-.977C18.726 18.013 20 16.35 20 14.577c0-1.948-1.545-3.754-4.032-4.713zm.874-4.759l.614.598-5.823 5.732-.614-.598 5.823-5.732zm-6.474 6.198l.878.858a1.065 1.065 0 01-.294.804c-.429.368-.968.59-1.537.63.109-.297.163-.61.16-.926a1.25 1.25 0 01.155-.817 1.29 1.29 0 01.637-.549zm5.878 7.017a9.985 9.985 0 01-4.246.898c-3.971 0-7.203-2.082-7.203-4.641 0-1.555 1.246-3.024 3.27-3.882.304.28.657.504 1.043.661-.23.388-.345.831-.332 1.28-.024.547-.057.82-.513 1.046l.166.74c.068.002.147.003.234.003a4.11 4.11 0 002.87-.928 1.862 1.862 0 00.508-1.358l.254-.25a5.725 5.725 0 003.635-1.194c2.025.857 3.27 2.327 3.27 3.882 0 1.466-1.105 2.865-2.956 3.743z"
        fill="currentColor"
      />
      <path
        d="M9.875 15.742h-.002a1.2 1.2 0 00-.838.342 1.153 1.153 0 00-.348.822c0 .308.125.604.347.822a1.2 1.2 0 00.837.343h.002a1.2 1.2 0 00.839-.341 1.153 1.153 0 00.002-1.645 1.2 1.2 0 00-.839-.343zm-.002 1.548a.418.418 0 01-.277-.113.38.38 0 01-.084-.418.396.396 0 01.362-.236c.103.001.202.042.275.114a.38.38 0 010 .54.395.395 0 01-.276.113zM13.915 15.75h-.002a1.2 1.2 0 00-.839.341 1.153 1.153 0 00-.347.822c0 .309.124.604.345.823a1.2 1.2 0 00.838.343h.003a1.2 1.2 0 00.838-.341c.223-.219.348-.514.348-.823 0-.308-.125-.604-.347-.822a1.2 1.2 0 00-.837-.343zm.273 1.437a.394.394 0 01-.275.11h-.001a.395.395 0 01-.275-.113.38.38 0 010-.54.395.395 0 01.553 0 .38.38 0 01-.002.543zM16.72 13.836h-.003a1.2 1.2 0 00-.838.341 1.153 1.153 0 00-.002 1.645 1.2 1.2 0 00.838.343h.003a1.2 1.2 0 00.838-.342 1.153 1.153 0 00.001-1.645 1.2 1.2 0 00-.838-.342zm.273 1.436a.39.39 0 01-.275.112h-.002a.397.397 0 01-.276-.113.38.38 0 01.06-.59.396.396 0 01.494.048.38.38 0 010 .543zM7.126 13.813h-.002a1.2 1.2 0 00-.839.341 1.154 1.154 0 00-.348.823c0 .308.125.604.347.823.222.218.523.342.838.343h.002c.315 0 .616-.124.838-.342.223-.218.348-.514.348-.822 0-.309-.124-.605-.346-.823a1.201 1.201 0 00-.838-.344zm-.002 1.548a.371.371 0 01-.277-.113.381.381 0 01-.084-.417.397.397 0 01.361-.237c.104 0 .203.041.276.113a.38.38 0 010 .541.396.396 0 01-.276.113z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcLayoutOptions;
