import * as React from "react";
function SvgIcAccessibility(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.14 13.8a3.436 3.436 0 00-2.367-.925H6.227a3.436 3.436 0 00-2.366.924 3.057 3.057 0 00-.986 2.238v3.088H3.95v-3.088a2.062 2.062 0 01.664-1.507 2.343 2.343 0 011.613-.629h3.546c.61 0 1.182.224 1.612.629.428.403.664.938.665 1.507v3.088h1.075v-3.088a3.057 3.057 0 00-.986-2.238zM21.446 4.82l-5.462 5.822-2.43-2.593-.726.773 3.156 3.364 6.187-6.593-.725-.773zM7.86 11.883a3.754 3.754 0 01-3.75-3.75 3.754 3.754 0 013.75-3.75 3.754 3.754 0 013.75 3.75 3.754 3.754 0 01-3.75 3.75zm0-6.5a2.753 2.753 0 00-2.75 2.75 2.753 2.753 0 002.75 2.75 2.753 2.753 0 002.75-2.75 2.753 2.753 0 00-2.75-2.75z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcAccessibility;
