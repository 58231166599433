import * as React from "react";
function SvgIcLinkedIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.896 5.439c0-.373.303-.676.676-.676h12.387c.374 0 .676.303.676.676v12.388a.676.676 0 01-.676.676H5.572a.676.676 0 01-.676-.676V5.439zm.676-1.306c-.722 0-1.306.585-1.306 1.306v12.388c0 .721.584 1.306 1.306 1.306h12.387c.722 0 1.307-.585 1.307-1.306V5.439c0-.721-.585-1.306-1.306-1.306H5.571zm1.89 3.688a.718.718 0 00-.194.513c0 .414.272.713.695.713h.012c.236 0 .41-.083.526-.203a.723.723 0 00.19-.508.763.763 0 00-.194-.518.68.68 0 00-.51-.198.713.713 0 00-.526.201zm-.382-.373c.226-.23.543-.36.906-.36.364 0 .675.13.897.362.22.23.334.542.341.877v.006c0 .335-.116.649-.338.88a1.237 1.237 0 01-.91.367h-.013c-.744 0-1.228-.564-1.228-1.246 0-.342.12-.656.345-.886zm5.794 3.96c.206-.242.365-.392.576-.491.212-.1.504-.16.994-.16.574 0 1.063.182 1.409.54.346.359.579.924.579 1.745v2.863H14.95V13.26c0-.412-.084-.796-.296-1.085-.22-.3-.556-.464-.978-.464-.677 0-1.048.457-1.209.854a.906.906 0 00-.06.3 3.495 3.495 0 00-.004.208V15.906h-1.46l.002-.546c.002-.492.005-1.151.006-1.826.003-1.07.003-2.19-.003-2.724h1.456v.424a.267.267 0 00.469.174zm-2.387 4.952a.267.267 0 01-.078-.19l.004-.813.006-1.824c.003-1.355.003-2.75-.01-2.975a.267.267 0 01.267-.281h1.995c.148 0 .267.12.267.267v.06a1.7 1.7 0 01.284-.17c.314-.147.695-.21 1.222-.21.689 0 1.327.22 1.792.703.466.482.73 1.193.73 2.116v3.129c0 .147-.12.267-.268.267h-2.014a.267.267 0 01-.266-.267v-2.913c0-.35-.074-.607-.193-.77-.11-.149-.277-.246-.548-.246-.383 0-.602.244-.715.522l-.002.005a.394.394 0 00-.02.123c-.003.05-.003.102-.002.162v3.117c0 .147-.12.267-.267.267h-1.995a.267.267 0 01-.19-.08zM7.07 10.276a.267.267 0 00-.267.267v5.628c0 .147.12.267.267.267h1.776c.147 0 .266-.12.266-.267v-5.628a.267.267 0 00-.266-.267H7.07zm.267 5.628V10.81h1.242v5.095H7.337z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcLinkedIn;
