import * as React from "react";
function SvgIcApps(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.527 13.818a2.292 2.292 0 11-.001-4.585 2.292 2.292 0 010 4.585zm0-3.834a1.542 1.542 0 10-.001 3.083 1.542 1.542 0 000-3.083zM6.292 8.584a2.292 2.292 0 110-4.584 2.292 2.292 0 010 4.584zm0-3.834a1.542 1.542 0 100 3.083 1.542 1.542 0 000-3.083zM11.526 8.584a2.292 2.292 0 110-4.584 2.292 2.292 0 010 4.584zm0-3.834a1.542 1.542 0 100 3.084 1.542 1.542 0 000-3.084zM16.76 8.584a2.292 2.292 0 110-4.584 2.292 2.292 0 010 4.584zm0-3.834a1.542 1.542 0 100 3.084 1.542 1.542 0 000-3.084zM6.292 13.818a2.291 2.291 0 110-4.582 2.291 2.291 0 010 4.582zm0-3.834a1.542 1.542 0 100 3.085 1.542 1.542 0 000-3.085zM16.761 13.818a2.292 2.292 0 110-4.585 2.292 2.292 0 010 4.585zm0-3.834a1.542 1.542 0 100 3.083 1.542 1.542 0 000-3.083zM6.292 19.052a2.292 2.292 0 110-4.584 2.292 2.292 0 010 4.584zm0-3.833a1.541 1.541 0 100 3.082 1.541 1.541 0 000-3.082zM11.526 19.052a2.292 2.292 0 110-4.583 2.292 2.292 0 010 4.583zm0-3.833a1.54 1.54 0 100 3.082 1.54 1.54 0 000-3.082zM16.761 19.052a2.292 2.292 0 110-4.583 2.292 2.292 0 010 4.583zm0-3.833a1.541 1.541 0 10-.001 3.082 1.541 1.541 0 00.001-3.082z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcApps;
