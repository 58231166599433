import * as React from "react";
function SvgIcHide(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.703 15.845l13.713-8.033.38.648-13.714 8.032-.379-.647zM9.445 12.196l.84-.492a1.54 1.54 0 011.451-1.04c.093.001.185.01.276.028l.825-.483a2.266 2.266 0 00-1.1-.295 2.294 2.294 0 00-2.292 2.282zM11.734 13.748a1.526 1.526 0 01-.392-.056l-.795.466a2.27 2.27 0 001.187.34 2.294 2.294 0 002.291-2.291c0-.028-.007-.054-.008-.082l-.796.466a1.54 1.54 0 01-1.487 1.158z"
        fill="currentColor"
      />
      <path
        d="M19.409 12.148A8.293 8.293 0 0017.95 9.82l-.66.386a7.548 7.548 0 011.364 2.084 7.526 7.526 0 01-2.813 3.331 5.263 5.263 0 00.88-5.082l-.677.397c.146.437.223.893.23 1.354a4.546 4.546 0 01-4.54 4.541h-.002a4.52 4.52 0 01-3.298-1.437l-1.333.781a8.284 8.284 0 004.63 1.406h.003a8.266 8.266 0 007.674-5.149l.058-.142-.058-.142zM6.012 14.209a7.559 7.559 0 01-1.2-1.917A7.53 7.53 0 017.626 8.96a5.175 5.175 0 00-.943 4.855l.67-.392a4.488 4.488 0 01-.16-1.131 4.547 4.547 0 014.541-4.542h.002a4.52 4.52 0 013.133 1.27l1.27-.744A8.273 8.273 0 0011.735 7h-.002a8.266 8.266 0 00-7.676 5.15L4 12.292l.058.142c.32.781.758 1.51 1.298 2.159l.656-.384z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgIcHide;
